import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import { useEffect, useState } from 'react';

const doubleRender: any = {
  current: undefined
};

function Auth() {
  const [authToken, setAuthToken] = useState();
  useEffect(() => {
    if(doubleRender.current) return
    doubleRender.current = true;

    fetch('https://hello-world-icy-thunder-0e44.pgo.workers.dev').then((e) => e.json()).then((d) => setAuthToken(d.data.token));

  },[]);
  if(!authToken) return null;
  return (<App authToken={authToken} />);
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  // NOTE: Not using StrictMode to avoid the double execution of useEffect
  // while trying out the sample
  <Auth />
);
