export default function ThankYou() {
  return (
    <div className="fixed inset-0 w-full h-full flex place-items-center justify-center bg-white p-4">
      <div className="flex flex-col items-center">
        <img
          src="/thank-you.png"
          className="w-full max-w-sm rounded-2xl mb-8"
          alt="Thank You! EggDOnald flying to the moon!"
        />
        <p className="text-orange-700 text-3xl font-semibold text-center mb-4">Thank you for joining our livestream!</p>

        <p>Check out <a className="text-blue-600" href="https://dyte.io">dyte.io</a> to learn more about Dyte!</p>
      </div>
    </div>
  );
}
