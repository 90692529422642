// import { useEffect } from "react";
// import { DyteSpinner, provideDyteDesignSystem } from "@dytesdk/react-ui-kit";
// import { DyteProvider, useDyteClient } from "@dytesdk/react-web-core";
// import Meeting from "./Meeting";
import ThankYou from "./ThankYou";

function App({ authToken }: { authToken: string }) {
  // const [meeting, initMeeting] = useDyteClient();

  // useEffect(() => {
  //   const searchParams = new URL(window.location.href).searchParams;

  //   // const authToken = searchParams.get('authToken');

  //   // pass an empty string when using v2 meetings
  //   // for v1 meetings, you would need to pass the correct roomName here
  //   const roomName = searchParams.get("roomName") || "";

  //   if (!authToken) {
  //     alert(
  //       "An authToken wasn't passed, please pass an authToken in the URL query to join a meeting."
  //     );
  //     return;
  //   }

  //   initMeeting({
  //     authToken,
  //     roomName,
  //     defaults: {
  //       video: false,
  //       audio: false,
  //     },
  //   });

  //   provideDyteDesignSystem(document.body, {
  //     theme: "light",
  //     colors: {
  //       brand: {
  //         700: "#da552f",
  //         600: "#de6644",
  //         500: "#e17759",
  //         400: "#e5886d",
  //         300: "#e99982",
  //       },
  //       text: "#000",
  //     },
  //   });
  // }, []);

  // if (import.meta.env.DEV) {
  //   Object.assign(window, { meeting });
  // }

  // if (!meeting) {
  //   return (
  //     <div className="fixed inset-0 w-full h-full bg-white flex items-center justify-center">
  //       <DyteSpinner className="w-16 h-16" />
  //     </div>
  //   );
  // }

  return <ThankYou />;
}

export default App;
